@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-color: #f1f0f2;
}

body {
  color: rgb(var(--foreground-rgb));
  background: var(--background-color);
}

.btn {
  @apply py-2 px-4 rounded font-medium text-white;
  background-color: #130722;
}

.btn:hover {
  box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
}

.input {
  @apply shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none
}
